<template>
<div v-if="loaded">  
    <h1 class="title" style="margin-bottom: 30px; margin-top: 50px">{{ installationName }}</h1>
        <div v-if="step1">
            <p>Create your avatar in a few steps and jump into the experience!</p>
           <hr style="margin-bottom: 50px; margin-top: 50px"/>
        <vs-row class="vertical-align">
            <vs-col w="4" sm="12">
                <h3>1. Create your Avatar <span v-if="hasImage">✔️</span></h3>
                 <vs-row v-if="!isUser">
            <vs-col w="6" sm="12">
            <image-uploader
            :preview="false"
            :className="['fileinput', { 'fileinput--loaded': hasImage }]"
            capture="environment"
            :debug="0"
            doNotResize="gif"
            :autoRotate="true"
            outputFormat="file"
            @input="setImage"
        >
        <label for="fileInput" slot="upload-label" style="max-height: 31px;" class="vs-button vs-button--null vs-button--size-null vs-button--primary vs-button--flat"><div class="vs-button__content"> Upload Photo</div>
            </label>
        </image-uploader>
              </vs-col>
               <vs-col w="6" sm="12">
               <vs-button
            flat
            block
            @click="camera"
            default
        >
            Camera
        </vs-button>
              </vs-col>
         </vs-row>
          <router-link v-if="isUser" to="/#/user">Manage here your User data</router-link> 
         </vs-col>
            <vs-col w="4" sm="12">
                <h3>2. Your name <span v-if="hasName">✔️</span></h3> <vs-row>
            <vs-col w="12">
                 <vs-input :readonly="isUser" v-model="name" placeholder="Name" class="colElement"/>
                      </vs-col>
         </vs-row>
         </vs-col>
            <vs-col w="4" sm="12">
                <h3>3. Select your device <span v-if="hasDevice">✔️</span></h3> <vs-row>
            <vs-col w="12">
                 <vs-select placeholder="Select" v-model="device"  class="colElement">
        <vs-option 
            :key="i"
            v-for="(item, i) in devices"
            :label="item.deviceName" :value=" item.deviceId" 
             >
          {{ item.deviceName }}
        </vs-option>
      </vs-select>
                      </vs-col>
         </vs-row>
         </vs-col>
         </vs-row>
         <p style="margin-bottom: 30px; margin-top:50px">The selfie has to be with a neutral<br>
facial expression and homagenic background.</p>
        <vs-button
            flat
            @click="confirm"
            default
            style="margin-left: auto; margin-right:auto; padding-left: 30px; padding-right: 30px"
            :disabled="(!hasImage || !hasName || !hasDevice)"
        >
            OK
        </vs-button>
         <hr style="margin-bottom: 50px; margin-top: 50px"/>
         <p v-if="!isUser"> Are you resgistered user?  <router-link to="/login">Login</router-link> </p>
        </div>
        <div v-if="step2">
            <hr style="margin-bottom: 50px; margin-top: 50px"/>
            <h2 style="font-weight:400; margin:0px">{{ name}}, do you like your avatar?</h2>
            <img :src="avatar" style="max-width: 75%; width:315px; margin:20px; padding:20px;"/>
            <vs-button
            flat
            @click="confirmAvatar"
            default
            style="margin-left: auto; margin-right:auto; padding-left: 30px; padding-right: 30px"
        >
            YES
        </vs-button>
        <p><span class="link" @click="changeAvatar">No, I want to change it.</span></p>
            <hr style="margin-bottom: 50px; margin-top: 50px"/>
        </div>
         <div v-if="step3">
            <hr style="margin-bottom: 50px; margin-top: 50px"/>
            <h2 style="font-weight:400; margin-top:200px; margin-bottom:200px">Thank you!<br> You are ready to jump into VR! </h2>
           
           
            <vs-button
            flat
            @click="goBack"
            default
            style="margin-left: auto; margin-right:auto; padding-left: 30px; padding-right: 30px"
        >
            GO BACK
        </vs-button>
            <hr style="margin-bottom: 50px; margin-top: 50px"/>
        </div>
          <div v-if="stepCam">
            <hr class="no-offset" style="margin-bottom: 50px; margin-top: 50px"/>
            <web-cam ref= "webcam" width= "500" height="500" style="max-width: 100%; margin-left: auto; margin-right: auto" :selectFirstDevice="true" :autoplay="true" @cameras="getCameras" />
            <div style="display:flex;justify-content: center;">
            <vs-button
            flat
            @click="capture"
            default
            style="padding-left: 30px; padding-right: 30px"
        >
            CAPTURE
        </vs-button>
                    <vs-button
            flat
            @click="captureCancel"
            danger
            style="padding-left: 30px; padding-right: 30px"
        >
            CANCEL
        </vs-button></div>
            <hr style="margin-bottom: 50px; margin-top: 50px"/>
        </div>
    </div>
</template>

<script>
const avatarDefault = require('@/assets/avatar-default.png');

import ImageUploader from 'vue-image-upload-resize'
import { WebCam } from "vue-web-cam";


export default {
    data() {
      return {
        step1: true,
        step2: false,
        step3: false,
        stepCam: false,
        avatar: avatarDefault,
        loaded: false,
        hasImage: false,
        hasName: false,
        hasDevice: false,
        image: null,
        loading: null,
        installationName: '',
        device: '',
        devices: [],
        name: '',
        id: 0,
        user: 0,
        isUser: false,
        capturedImg: '',
        avatarError: '',
        qs: ''
      }  
    },
    props:['userdata'],
    components: {
      ImageUploader,
      WebCam,
    },
    created(){
      this.getData()
      this.qs = this.$route.query.test
    },
    methods: {
      reset(){
        this.avatar = avatarDefault
        this.image = null
        this.hasImage = false
        this.device = ''
        this.name= ''
        this.user = 0
        this.capturedImg = null
      },
      getData(){
          this.loading = this.$vs.loading()
          const slug = this.$route.params.id
          this.$http.get('/public-installation/' + slug).then(resp => {
                  this.loading.close()
              if (resp.data.response){
                  const data = resp.data.response
                  this.id = data.id
                  this.installationName = data.name
                  this.devices = data.devices
                  this.loaded = true
              } else {
                  this.$router.push({name: 'login'});
              }
         })
         if (this.userdata.userId !== undefined){
             this.user = this.userdata.userId
             this.name = this.userdata.userName
             this.isUser = true
             this.hasImage = true
         }
      },
      capture: async function(){
          //this.$refs.webcam.changeCamera(0)
          const image = await this.$refs.webcam.capture()
          this.capturedImg = image
          this.hasImage = true
          this.stepCam = false
          this.step1 = true
      },
      captureCancel(){
          this.stepCam = false
          this.step1 = true
      },
      setImage(output) {
        /**/
        this.hasImage = true;
        this.image = output;
        console.log('type', output.type)
        console.log(output)
      },
      getCameras(data){
          console.log(data)
          this.loading.close()
      },
      confirm(){
        this.step1 = false
        this.loading = this.$vs.loading({
            text: 'Wait please, we are working on your avatar…'
        })
        let formData = new FormData();
        formData.append("file", this.image);
        formData.append("name", this.name);
        formData.append("device", this.device);
        formData.append("user", this.user);
        formData.append("isUser", this.isUser);
        formData.append("maxContentLength", Infinity);
        formData.append("maxBodyLength", Infinity);
        formData.append("selfie", this.capturedImg);
        this.$httpFile.post('/set-relation/' + this.id, formData).then(response => {
            if (response.data.error) {
                this.loading.close()
                this.step1 = true
                this.avatar = avatarDefault
                this.image = null
                this.hasImage = false
                this.$swal.fire(
                '',
                'Sorry, we could not recognise your face.',
                'error'
              )
            } else{
            if (this.isUser){
                this.loading.close()
                this.step3 = true
            } else {
            this.user = response.data.relation.user
            this.avatar = response.data.urls.preview
            this.loading.close()
            this.step2 = true
            }
        }
        })
      },
      camera(){
        this.loading = this.$vs.loading()  
        this.step1 = false
        this.stepCam = true
      },
      changeAvatar(){
        //TODO delete avatar
        this.avatar = avatarDefault
        this.image = null
        this.hasImage = false
        this.step2 = false
        this.step1 = true
      },
      confirmAvatar(){
          const data = {user: this.user, device: this.device}
            this.$http.post('/confirm-avatar/', JSON.stringify(data)).then(resp => {
                if (resp.data){
                    this.step2 = false
                    this.step3 = true
                } else{
                    this.step2 = false
                    this.step1 = true
                    this.avatarError = 'error'
                }
            })
      }, 
      goBack(){
          if (this.isUser === true){
             this.$router.push('/user');
          } else { 
            this.reset()
            this.step3 = false
            this.step1 = true
          }
        
        this.step3 = false
        this.step1 = true
      },
    },
    watch: {
        $route (to, from){
            if (to !== from) {
                this.getData()
            }
        },
        name(){
            if (this.name != "") {
                this.hasName = true
            } else {
                this.hasName = false
            }
        },
        device(){
            if (this.device != "") {
                this.hasDevice = true
            } else {
                this.hasDevice = false
            }
            
        }
    }

}
</script>
<style>
#fileInput {
  display: none;
}
.colElement{
    width: 80%!important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}
.vs-loading__load__text{
font-family: Poppins,Helvetica,Arial,sans-serif;
font-size: 16px;
}
.link{
 cursor: pointer;
 text-decoration: underline;
}
.link:hover{
 text-decoration: none;
}
a{ 
    color:#055cd5
}

@media (max-width: 600px) {
  .no-offset{
    margin: 0px !important;
  }
  .vertical-align{
    flex-direction: column !important;
  }
  video{
      height: 100%!important;
      margin-top: 50px;
      margin-bottom: 50px;
  }
}
</style>
