<template>
<div>
  <installation-user v-if="userdata.userType === 2" :userdata="userdata"/>
  <normal-user v-if="userdata.userType === 1" :userdata="userdata"/>
</div>
</template>
<script>
import normalUser from './normalUser.vue'
import installationUser from './installationUser.vue'

export default {
    data() {
      return {
      }  
    },
    props:['userdata'],
    components: {
      normalUser,
      installationUser
    },
    mounted(){
      if (this.userdata.userType === 9){
        this.$router.push('/users');
      } else if (this.userdata.userType === 2 && this.$route.name !== 'uInstallation'){
        this.$router.push('/installation');
      } else if(this.$route.name !== 'User' && this.userdata.userType === 1){
        this.$router.push('/user');
      } else if(this.userdata.userType === 0){
        this.$router.push('/Login');
      }
    }
}
</script>
