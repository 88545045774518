 <template>
    <div v-if="loaded">
      <h2 style="text-align:left">USERS</h2>
      <vs-table
        v-model="selected"
        >
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'email')">
              Email
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'name')">
              User Name
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'id')">
              User ID
            </vs-th>
            <vs-th style="max-width:100px"  sort @click="users = $vs.sortData($event ,users, 'id')">
              Has Avatar
            </vs-th>
            <vs-th style="max-width:100px" sort @click="users = $vs.sortData($event ,users, 'userType')">
              Type
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(users, search), page, max)"
            :data="tr"
            :is-selected="!!selected.includes(tr)"
            not-click-selected
            @click="goTo(tr.userId)"

          >
            <vs-td>
            {{ tr.email }}
            </vs-td>
            <vs-td>
              {{ tr.userName }}
            </vs-td>
            <vs-td>
            {{ tr.userId }}
            </vs-td>
            <vs-td style="text-align: center!important;">
           <i v-if="tr.avatar.avatarId === null" style="font-size:30px" class='bx bx-x'></i>
           <i v-if="tr.avatar.avatarId !== null" style="font-size:30px" class='bx bx-check'></i>
            </vs-td>
            <vs-td>
            {{ (tr.userType === 9)? 'Admin' : 'User' }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(users, search), max)" />
        </template>
      </vs-table>
    </div>
  </template>

<script>
    export default {
      data:() => ({
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: false,
        selected: [],
        users: [],
        loaded:  false,
      }),
      props:['userdata'],
      mounted(){
        if (this.userdata.userType === 9){
          this.getUsers()
        } else {
           this.$router.push('/user');
        }
      },
      methods: {
        goTo(id){
          this.$router.push('/users/' + id);
        },
        getUsers(){
          this.$http.get('/get-users').then(response => {
            console.log(response)
            const userdata = response.data.response
            this.users = userdata
            this.loaded = true
          })
        .catch(() => {
          this.error = true;
        });
        }
      }
    }
    </script>
    <style type="text/css">
    .vs-table__td{
      text-align:left!important
    }
      .th-center div{
      text-align:center!important
    }
    </style>
