<template>
    <div style="text-align: left; width: 95%;">
         <h5>Device Name:</h5>
            <vs-input v-model="deviceName" placeholder="Device Name" :class="nameError" @change="onChange('nameError')" />
        <br>
        <h5>Device Serial Number:</h5>
            <vs-input v-model="serialNumber" placeholder="Serial Number" :class="serialError" @change="onChange('serialError')"/>
       <br>
        <vs-button
        flat
        :active="true"
        @click="newDevice"
      >
        Add Device
      </vs-button>
    </div>
</template>

 <script>
 /* eslint-disable vue/no-side-effects-in-computed-properties */
   export default {
      data:() => ({
          deviceName: '',
          serialNumber: '',
          nameError: '',
          serialError: '',

      }),
      props: ['addDevice'],
      computed:{
          filled(){
              let error = false
              if (this.deviceName === ''){ 
                  error = true
                  this.nameError = 'error'
              } 
              if (this.serialNumber === ''){ 
                  error = true
                  this.serialError = 'error'
              } 

              if (error === true){
                  return false
              } else {
                return true
              }
          }
      },
      methods: {
          newDevice(){
              if (this.filled){
                this.addDevice({deviceName: this.deviceName, serialNumber: this.serialNumber});
              }
          },
          onChange(type){
              this[type] =  ''
          }
      },
   }
 </script>
 <style scoped>
 h5{
     margin: 0px!important;
 }
 </style>
 <style>
 .error div input{
     border: 1px solid red;
 }
 </style>