<template>
<div v-if="loaded">
     <h1 class="title" style="margin-bottom: 0px">Welcome, {{ userdata.userName }}!</h1>
     <img :src="avatar" style="width:256px; margin:40px; padding:20px;"/>
     <vs-row>
         <vs-col class="vertical-align" style="display: flex; justify-content: center;  text-align: center; align-content: center;" w="12">
            <vs-button
            flat
            @click="update"
        >
            Update
        </vs-button>
        <vs-button
            flat
            @click="changePass"
        >
            Change Password
        </vs-button>
        <image-uploader
        :preview="false"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        capture="environment"
        :debug="0"
        doNotResize="gif"
        :autoRotate="true"
        outputFormat="file"
        @input="setImage"
      >
      <label for="fileInput" slot="upload-label" class="vs-button vs-button--null vs-button--size-null vs-button--primary vs-button--flat"><div class="vs-button__content"> Change Avatar </div>
        </label>
      </image-uploader>
        <vs-button
            flat
            @click="deleteUser"
            danger
        >
            Delete User
        </vs-button>
         </vs-col> 
         <vs-col class="no-offset" offset="3" lg="6" sm="12">
        <vs-row style="margin-top: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2">
         Name:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10">
              <vs-input v-model="userName" placeholder="John Doe" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-top: 10px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2">
         Email:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10">
              <vs-input v-model="userEmail" placeholder="john@doe.com" />
        </vs-col>
      </vs-row>
        </vs-col> 
    </vs-row>
</div>
</template>
<script>
const avatarDefault = require('@/assets/avatar-default.png');

import ImageUploader from 'vue-image-upload-resize'

export default {
    data() {
      return {
        userId: '',
        userName: '',
        userEmail: '',
        avatar: avatarDefault,
        loaded: false,     
        hasImage: false,
        mage: null,
        loading: null,
      }  
    },
    props:['userdata'],    
    components: {
      ImageUploader
    },
    mounted(){
        this.userId = this.userdata.userId
        this.userName = this.userdata.userName
        this.userEmail = this.userdata.email
        this.avatarUrl = this.userdata.avatar.previewUrl
        if (this.userdata.avatar.avatarId !== null){
            this.avatar = this.userdata.avatar.previewUrl
        }
        this.loaded= true
    },
    methods: {
        update(){
           const data = this.userdata
           data. userName = this.userName
           data.email = this.userEmail
           console.log(data)
           this.$http.put('/user/' + this.userId, JSON.stringify(data)).then(() => {
              this.$swal.fire({
                title:  'Updated!',
                text:  'User data has been updated.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
        },
      setImage(output) {
        this.loading = this.$vs.loading({
            text: 'Wait please, we are working on your avatar…'
          })
        this.hasImage = true;
        this.image = output;
        console.log('type', output.type)
         let formData = new FormData();
        formData.append("file", output);
        this.$httpFile.post('/upload/' + this.userId, formData).then(response => {
          if (response.error) {
              this.loading.close()
               this.$swal.fire(
                '',
                'Sorry, we could not recognise your face.',
                'error'
              )
          } else {
            const url = response.data.urls.preview
            this.loading.close()
            this.avatar = url + '?id=' + Math.floor(Date.now() / 1000)
            //console.log(url)
          }
        })
      },
        changePass(){
          this.$swal.fire({
          title:  'Are you sure?',
          text:  'Password will be changed',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, change it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.put('/new-password/' + this.userId).then(() => {
              this.$swal.fire({
                title:  'Pasword Changed!',
                text:  'An email has been sent with a new Password.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
          }
        })
        },
        deleteUser() {
          this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.delete('/user/' + this.userId).then(() => {
            this.$cookies.remove('loginUnivrse')
              this.$swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
            this.$router.push({name: 'login'});
           })
          }
        })
        },
    }

}
</script>
<style>
#fileInput {
  display: none;
}
@media (max-width: 600px) {
  .no-offset{
    margin: 0px !important;
  }
  .vertical-align{
    flex-direction: column;
  }
}
</style>
