<template>
  <div class="login">
    <img class="logo" alt="Univrse logo" src="@/assets/logo.png">
    <h1 class="title" style="margin-bottom: 0px">Welcome to Univrse</h1>
    <p style="margin-top: 0px;margin-bottom: 30px;">If you are a registered user, please login below.</p>
    <div class="form">
       <vs-alert  danger :progress="progress" v-model="error" style="margin-bottom:40px">
      User or Password incorrect.
    </vs-alert>
         <vs-input  v-model="userName"  label="User Email" class="fullwidth">
        <template #icon>
          <i class='bx bx-user'></i>
        </template>
      </vs-input>
      <vs-input type="password" icon-after v-model="userPassword" label="Password" class="fullwidth" 
        :visiblePassword="hasVisiblePassword" @click-icon="hasVisiblePassword = !hasVisiblePassword">>
        <template #icon>
          <i v-if="!hasVisiblePassword" class='bx bx-show-alt'></i>
          <i v-else class='bx bx-hide'></i>
        </template>
      </vs-input>
     <vs-button
        flat
        id="login-button"
        @click="login"
        :disabled="!submitActive"
      >
        Login
      </vs-button>
      </div>
      <div class="register">
          <p>If you are a new user, sign up now!</p>
           <vs-button
        flat
        secondary
        center
        @click="register"
      >
        REGISTER ME
      </vs-button>
      </div>
  </div>
</template>

<script>
    export default {
      data:() => ({
        userName: '',
        userPassword: '',
        error: false,
        submitActive: false,
        pActive: false,
        hasVisiblePassword: false,
        time: 3000,
        progress: 0
      }),
      mounted(){
        /* console.log('loginUnivrse', this.$cookies.get('login') )
        if (this.$cookies.get('loginUnivrse') === 'true' ){
          console.log('ESTA LOGUEADO')
          this.$router.push('/users');
        } */
      },
      methods: {
        login(){
          this.error = false
          const data = {userName: this.userName, userPassword: this.userPassword, getToken: true}
          this.$http.post('/user-auth', JSON.stringify(data)).then(response => {
            const token = response.data.token
            this.$cookies.set('loginUnivrse',token);
            this.$router.push('/users');
          })
        .catch(() => {
          this.error = true;
        });
        },
        register(){
            this.$router.push('/registration');
        }
      },
      watch: {
        userName: function(){
          if (this.userName !== '' && this.userPassword !== '') {
            this.submitActive = true
          } else {
            this.submitActive = false
          } 
        },
        userPassword: function(){
          if (this.userName !== '' && this.userPassword !== '') {
            this.submitActive = true
          }else {
            this.submitActive = false
          } 
        },
         error(val) {
          if(val) {
            var interval = setInterval(() => {
              this.progress++
            }, this.time / 100);

            setTimeout(() => {
              this.error = false
              clearInterval(interval)
              this.progress = 0
            }, this.time);
          }
        }
      }
    }
  </script>


<style>
.fullwidth, .fullwidth input{
    width: 100%!important;
}
.fullwidth{
    margin-bottom: 30px;
}
.logo {
    width:120px;
}
h1{
  margin-top: 0px!important;
}
.login {
  padding: 0px!important;
}
.title {
  text-align: center;
  font-weight: 400;
}
.form {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
}
#login-button{
        width: 50%;
    margin-left: auto;
}
.register{
  margin: 4rem auto;
}
.register button{
  margin: 1rem auto;
}

@media (max-width: 600px) {
  .form {
    width: 76%!important;
    min-width: 76%!important;
  }
}
</style>
