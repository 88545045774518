<template>
     <div v-if="loaded">
      <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="3" sm="12">
       <h2 style="text-align:left">INSTALLATIONS</h2>
      </vs-col>
      <vs-col class="float-right" vs-type="flex" vs-justify="right" vs-align="right" style="text-align: right"  w="9" sm="12">
        <vs-button
        flat
        :active="true"
        @click="openForm"
      >
        Add Installation
      </vs-button>
      </vs-col>
    </vs-row>
    <div class="mobile-blank-space"></div>
     <vs-table
        v-model="selected"
        >
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th sort @click="installations = $vs.sortData($event ,installations, 'name')">
              Installation Name
            </vs-th>
            <vs-th sort @click="installations = $vs.sortData($event ,installations, 'id')">
              Installation ID
            </vs-th>
            <vs-th sort @click="installations = $vs.sortData($event ,installations, 'email')">
              Email
            </vs-th>
            <vs-th style="max-width:100px"  sort @click="installations = $vs.sortData($event ,installations, 'id')">
              Devices
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(installations, search), page, max)"
            :data="tr"
            :is-selected="!!selected.includes(tr)"
            not-click-selected
            @click="goTo(tr.id)"

          >
            <vs-td>
            {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.id }}
            </vs-td>
            <vs-td>
            {{ tr.email }}
            </vs-td>
            <vs-td style="text-align: center!important;">
            {{ tr.devices.length }}
            </vs-td>
          </vs-tr>
        </template>
        
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(installations, search), max)" />
        </template>
      </vs-table>
      </div>
</template>
 <script>
import Vue from 'vue'
import NewInstallation from './AddInstallation.vue'

    export default {
      data:() => ({
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: 0,
        selected: [],
        installations: [],
        loaded: false,
      }),
      props: ['userdata'],
      mounted(){
        if (this.userdata.userType === 9){
        this.getInstallations()
        } else {
          this.$router.push('/');
        }
      },
      methods: {
        goTo(id){
          this.$router.push('/installations/' + id);
        },
        getInstallations(){
          this.$http.get('/get-installations').then(response => {
            console.log(response)
            const data = response.data.response
            this.installations = data
            this.loaded = true
          })
        .catch(() => {
            console.log('get-installations error')
          this.error = true;
        });
        },
          openForm() {
            const installationForm = new Vue({
              ...NewInstallation,
              parent: this,
              propsData: {addInstallation: this.addInstallation},
            }).$mount()
            this.$swal({
              title: '<span class="font-weight-bolder">Add Installation</span>',
              html: installationForm.$el,
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusCancel: false,
            })
          },
          addInstallation(data){
            this.$http.post('/installation', JSON.stringify(data)).then(() => {
              this.getInstallations()
              this.$swal.close()
          })
        .catch(() => {
          this.error = true;
        });
          },
      },
      watch: {
        $route(){
          this.getInstallations()
        },
      },
    }
    </script>
    <style type="text/css">
    .vs-table__td{
      text-align:left!important
    }
      .th-center div{
      text-align:center!important
    }
    </style>
