<template>
  <div class="login">
    <img class="logo" alt="Univrse logo" src="@/assets/logo.png">
    <h1 class="title">Welcome to Univrse</h1>
    <p>Darte de alta en la plataforma es muy sencillo. Simplemente introduce tu email y contraseña, <br> y recibirás tus datos a través del mismo.</p>
    <div class="form">
         <vs-input  v-model="userName"  label="User Name" class="fullwidth">
        <template #icon>
          <i class='bx bx-user'></i>
        </template>
      </vs-input>
         <vs-input  v-model="userEmail"  label="User Email" class="fullwidth">
        <template #icon>
          <i class='bx bx-at'></i>
        </template>
      </vs-input>
      <vs-input type="password" icon-after v-model="userPassword" label="Password" class="fullwidth" style="margin-bottom: 0px!important">
        <template #icon>
          <i :class="'bx bx-' + passOk1"></i>
        </template>
      </vs-input>
       <password-meter :password="formatedPassword"  @score="onScore" style="margin-bottom: 10px!important"/>
      <vs-input type="password" icon-after v-model="userPassword2" label="Repeat Password" class="fullwidth" style="margin-top: 20px">
        <template #icon>
          <i :class="'bx bx-' + passOk2"></i>
        </template>
      </vs-input>
     <vs-button
        flat
        id="login-button"
        @click="register"
      >
        Register
      </vs-button>
      </div>
      <div class="register">
           <vs-button
        flat
        secondary
        center
        @click="$router.push('/login')"
      >
        Ya estoy  Registrado
      </vs-button>
      </div>
  </div>
</template>

<script>
 import passwordMeter from "vue-simple-password-meter";
    export default {
      data:() => ({
        userName: '',
        userEmail: '',
        userPassword: '',
        userPassword2: '',
        passOk1: 'x',
        passOk2: 'x',
        score: 0,
        error: false,
         }),
      components: { passwordMeter },
      computed: { 
        formatedPassword() {
          let returned = ''
          if (this.userPassword.length === 0) {
            returned = ''
          } else if (this.userPassword.length < 3) {
            returned = '1' + this.userPassword
          } else if (this.userPassword.length < 6) {
            returned = '1A' + this.userPassword
          } else {
            returned = '1A!a' + this.userPassword
          }
          return returned
        }
       },
      methods: {
        register(){
          this.error = false
          if (this.userName !== '' && this.userPassword !== '' && this.score === 4 && this.userPassword === this.userPassword2 && this.validateEmail(this.userEmail) ){
                 const data = {userName: this.userName, userEmail: this.userEmail, userPassword: this.userPassword}
                this.$http.post('/register', JSON.stringify(data)).then(response => {
                if (response.data){
                  if (response.data.result === false){
                    this.errorMsg(response.data.message)  
                  } else{
                     this.$swal.fire(
                      'Your user has been created.',
                      'An email has been sent with the login information.',
                      'success'
                    )
                    this.$router.push('/login')
                  }
                } else {
                  this.errorMsg('An error occurred. Please try again later.')  
                }
              })
          } else{
            let error = 'Error'
            if (this.userName === ''){
              error = 'User Name is required.'
            } else if (!this.validateEmail(this.userEmail)){
              error = 'Valid email is required.'
            } else if (this.score!== 4 ){
              error = 'Password stronger is required.'
            } else if (this.userPassword !== this.userPassword2  ){
              error = 'Password and Repeated Password fields do not match.'
            }
          this.errorMsg(error)
          }
        },
        errorMsg(error){
        this.$vs.notification({
            color: 'danger',
            position:'top-right' ,
            title: error
          })
        },
        check(){
            this.passOk1 = 'x'
            this.passOk2 = 'x'
            
          if ( this.score === 4){
            this.passOk1 = 'check'
          }
          if ((this.userPassword === this.userPassword2) && this.score === 4){
            this.passOk2 = 'check'
          }
        },
        onScore({ score }) {
          console.log(score);
          this.score = score;
          this.check()
        },
         validateEmail(value){
          // eslint-disable-next-line no-useless-escape
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,30})+$/.test(value))
            {
              return true
            } else {
              return false
            }
         },
      },
      watch: { 
        userPassword(){
          this.check()
        },
        userPassword2(){
          this.check()
        }
      }
    }
  </script>


<style>
h4{
font-family: Poppins, Helvetica, Arial, sans-serif;
}
.fullwidth, .fullwidth input{
    width: 100%!important;
}
.fullwidth{
    margin-bottom: 30px;
}
.logo {
    width:200px;
}
.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-weight: 400;
}
.form {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
}
#login-button{
        width: 50%;
    margin-left: auto;
}
.register{
  margin: 4rem auto;
}
.register button{
  margin: 1rem auto;
}
</style>
