<template>
  <div>
      <inst-admin v-if="userType === 10" :userdata="userdata"/>
      <inst-user v-if="userType !== 10" :userdata="userdata"/>
  </div>
</template>

<script>
import instAdmin from './PublicInstallationAdmin.vue'
import instUser from './PublicInstallationUser.vue'

export default {
    data() {
      return {
      }  
    },
    props:['userdata'],
    components: {
      instAdmin,
      instUser,
    },
    computed:{
      userType(){
        if (this.userdata.userType !== undefined){
          return this.userdata.userType
        }
        return 0
      }
    },
    methods: {
    }
}
</script>
<style>
</style>
