<template>
    <div style="text-align: left; width: 95%;">
         <h5>Installation Name:</h5>
            <vs-input v-model="installationName" placeholder="Installation Name" :class="nameError" @change="onChange('nameError')" />
        <br>
        <h5>Email:</h5>
            <vs-input v-model="installationEmail" placeholder="Email" :class="emailError" @change="onChange('emailError')"/>
       <br>
        <h5>Password:</h5>
           <vs-input type="password" icon-after v-model="installationPass"
        :visiblePassword="true" @click-icon="genPassword"  :class="passError"
         @change="onChange('passError')"
        >
        <template #icon>
          <i class='bx bx-reset'></i>
        </template>
      </vs-input>
       <br>
        <vs-button
        flat
        :active="true"
        @click="newInstallation"
      >
        OK
      </vs-button>
    </div>
</template>

 <script>
    var generator = require('generate-password');

 /* eslint-disable vue/no-side-effects-in-computed-properties */
   export default {
      data:() => ({
          installationName: '',
          installationEmail: '',
          installationPass: '',
          nameError: 'noError',
          emailError: 'noError',
          passError: 'noError',

      }),
      props: ['addInstallation'],
      mounted(){
          this.genPassword()
      },
      computed:{
          filled(){
              let error = false
              if (this.installationName === ''){ 
                  error = true
                  this.nameError = 'error'
              } 
              if (this.installationEmail === ''){ 
                  error = true
                  this.emailError = 'error'
              } 
              if (this.installationPass === ''){ 
                  error = true
                  this.passError = 'error'
              } 

              if (error === true){
                  return false
              } else {
                return true
              }
          }
      },
      methods: {
          newInstallation(){
              if (this.filled){
                this.addInstallation({installationName: this.installationName, installationEmail: this.installationEmail, installationPass: this.installationPass});
              }
          },
          onChange(type){
              this[type] =  'noError'
          },
        genPassword(){
          var password = generator.generate({
              length: 10,
              numbers: true
            });
            this.installationPass = password;
            this.onChange('passError')
        }
      },
   }
 </script>
 <style scoped>
 h5{
     margin: 0px!important;
 }
 </style>
 <style>
 .error div input{
     border: 1px solid red;
 }
 
 .noError div input{
     border:unset!important;
 }
 </style>