<template>
<div v-if="loaded">
    <div class="installationHeader">
        <img :src="logo" style="width:50px;"/>
    <h1>{{ installationName }}</h1>
    </div>
     <div v-if="step === 1" class="steps">
        <div class="step">{{ setStep(1) }}</div>
        <h2>Take a Selfie</h2>
         <p style="margin:0px!important;">With natural facial expression and homogenic background.</p>
          <vs-button
            class="instBtn"
            flat
            block
            @click="camera"
            default
        >
            Photo
        </vs-button>
     </div>
          <div v-if="step === 'cam'" style="display: flex; justify-content: center; align-items: stretch; align-content: center;  flex-direction: row; height: calc(100vh - 102px);">
            <web-cam ref= "webcam" :class="(videoMirror) ? 'videoMirror' : ''" :deviceId="camId"  :height="$vssHeight" width="" :autoplay="true" @cameras="getCameras"/>
             <div style="width:120px; position: absolute; top: 130px;left: calc(50% - 60px); display: flex;  flex-wrap: nowrap; justify-content: center;  align-items: center; align-content: center;">
             <img v-if="cameras.length > 1 " :src="switchCameraImg" @click="switchCameras" style="width:40px; cursor:pointer"/>
            </div>
            <img v-if="cameras.length > 0 " :src="circle" 
            @click="capture" style="width:150px; position: absolute; bottom: 0px;left: calc(50% - 75px); cursor:pointer"/>
        </div>
        <div v-if="step === 2" class="steps">
        <div class="step">{{ setStep(2) }}</div>
        <h2>Your Information</h2>
         <vs-input :readonly="isUser" v-model="name" placeholder="You Name" class="instInput"/>
         <vs-input v-model="userHeight" placeholder="You Height (cm)" class="instInput"/>
         <div v-if="!isUser" class="back" @click="back(1)"><img :src="arrow" style="width:15px;"/> Back</div>
          <vs-button
            class="instBtn"
            :disabled="reqUserInfo && (name === '' || !(userHeight > 0))"
            flat
            block
            default
            @click="infoComplete = true"
        >
            Next
        </vs-button>
        </div>
        
        <div v-if="step === 3" class="steps">
        <div class="step">{{ setStep(3) }}</div>
        <h2>Select Device</h2>
         <perfect-scrollbar class="devices" :style="'height: '+ ($vssHeight - 460)  + 'px'">
             <div 
            :key="i"
            v-for="(item, i) in devices"
            class="device"
            :class="(item.deviceName === selDevice)? 'selected' : ''"
            :label="item.deviceName" :value=" item.deviceId"
            @click="selDevice = item.deviceName"
             >
          {{ item.deviceName }}
        </div>
         </perfect-scrollbar>
         <div v-if="reqUserInfo === true" class="back" @click="back(2)"><img :src="arrow" style="width:15px;"/> Back</div>
         <div v-if="reqUserInfo !== true && isUser === false" class="back" @click="back(1)"><img :src="arrow" style="width:15px;"/> Back</div>
          <vs-button
            class="instBtn"
            flat
            block
            default
            @click="confirm"
            :disabled="readyDisabled"
        >
            Ready!
        </vs-button>
        </div>
        <div v-if="step === 4" class="steps">
            <div style="position: absolute; top: calc(50% - 123px);">
            <Spinner size="100" line-size="10" line-fg-color="#F42958"/>
            <p style="font-size:25px">Your avatar is<br>getting ready...</p>
            </div>
            <p style="position:absolute; bottom: 15px">...it takes around 10 seconds</p>
        </div>

        <div v-if="step === 5" class="steps">
             <h2 v-if= "name === ''" style="font-size: 35px;margin:0px">Here you are!</h2>
             <h2 v-if= "name !== ''" style="font-size: 35px;margin:0px">{{ name}},<br> here you are!</h2>
            <img :src="avatar" style="max-width: 75%; width:315px; margin:20px; padding:20px;"/>
            <div style="position:absolute; bottom: 10px;display: flex; flex-wrap: nowrap; align-content: center; align-items: center;">
                <p>Put your headset on:</p> <div style="min-width: 20px;  height: 20px;  padding: 3px 5px 5px 5px;  border: 3px solid #000;  margin-left: 10px;  background: #F42958; color: #fff;">{{ selDevice }}</div>
            </div>
        </div>
        <div v-if="step === 6" class="steps">
             <img :src="sad" style="width:100px; margin-top: 30px"/>
             <h2 style="font-size:25px;">Sorry, we were not<br>able to create your<br>avatar.</h2>
             <p>Please make sure that your face is<br>well lit and visible on the photo.</p>
            <vs-button
                class="instBtn"
                flat
                block
                default
                @click="reset"
            >
                OK
        </vs-button>
        </div>
    </div>
</template>

<script>
const avatarDefault = require('@/assets/avatar-default.png');
const switchCamera = require('@/assets/switch-camera.png');
const flipImage = require('@/assets/flip.png');
const logoSvg = require('@/assets/logo2.svg');
const arrowSvg = require('@/assets/arrow-left-solid.svg');
const sadSvg = require('@/assets/Sad.svg');
const circleSvg = require('@/assets/circle.svg');
import Spinner from 'vue-simple-spinner'
import { WebCam } from "vue-web-cam";


export default {
    data() {
      return {
        step1: true,
        step2: false,
        step3: false,
        stepCam: false,
        avatar: avatarDefault,
        switchCameraImg: switchCamera,
        flipImage: flipImage,
        logo: logoSvg,
        arrow: arrowSvg,
        loaded: false,
        hasImage: false,
        hasName: false,
        hasDevice: false,
        image: null,
        loading: null,
        installationName: '',
        reqUserInfo: true,
        devices: [],
        name: '',
        id: 0,
        user: 0,
        isUser: false,
        capturedImg: '',
        avatarError: '',
        infoComplete: false,
        readyDisabled: true,
        selDevice: '',
        userHeight: '',
        sad: sadSvg,
        working: false,
        done: false,
        error: false,
        cameras:[],
        selCamera: -1,
        camId: '',
        circle: circleSvg,
        videoMirror: false,
      }  
    },
    props:['userdata'],
    components: {
      WebCam,
      Spinner,
    },
    created(){
      this.getData()
      if (this.$route.query.deviceId){
        this.selDevice = this.$route.query.deviceId
      }
    },
    computed: {
        step() {
            if (this.error){
                return 6
            }
            if (this.done){
                return 5
            }
            if (this.working){
                return 4
            }
            if (this.infoComplete && this.hasImage){
                return 3
            }
            if (this.hasImage) {
                return 2
            }
            if (this.stepCam){
                return 'cam'
            }
            return 1
        },
        device() {
            console.log('device', this.devices)
            const device = this.devices.find(el => el.deviceName === this.selDevice)
            if (device !== undefined) {
                return device.deviceId
            }
            return ''
        },
    },
    methods: {
        setStep(step){
            if (step === 2 &&  this.hasImage && this.isUser){
                return 1
            }
            if (step === 3 && this.reqUserInfo === false && this.hasImage === true && this.isUser === true){
                return 1
            }
            if (step === 3 && this.reqUserInfo === false){
                return 2
            }
            return step
        },
        switchCameras(){
            const cams = this.cameras.length
            this.videoMirror = !this.videoMirror
            this.selCamera = (this.selCamera === (cams - 1)) ? 0 : this.selCamera + 1

        },
        back(step) {
            if (step === 1){
                this.reset()
            }
            if (step === 2){
                this.infoComplete = false
            }
        },
      reset(){
        this.avatar = avatarDefault
        this.image = null
        this.hasImage = false
        this.selDevice = ''
        this.name= ''
        this.user = 0
        this.capturedImg = null
        this.infoComplete = false
        this.working= false
        this.done= false
        this.error= false
      },
      getData(){
          this.loading = this.$vs.loading()
          const slug = this.$route.params.id
          this.$http.get('/public-installation/' + slug).then(resp => {
              console.log(resp)
                  this.loading.close()
              if (resp.data.response){
                  const data = resp.data.response
                  this.id = data.id
                  this.installationName = data.name
                  this.devices = data.devices
                  this.devices = data.devices
                  this.loaded = true
                  this.reqUserInfo = (data.userInfo === 1)
                  if (data.userInfo === 0){
                       this.infoComplete = true
                  }
              } else {
                  this.$router.push({name: 'login'});
              }
         })
         if (this.userdata.userId !== undefined){
             this.user = this.userdata.userId
             this.name = this.userdata.userName
             this.isUser = true
             this.hasImage = true
         }
      },
      capture: async function(){
          //this.$refs.webcam.changeCamera(0)
          const image = await this.$refs.webcam.capture()
          this.capturedImg = image
          this.hasImage = true
          this.stepCam = false
          this.step1 = true
      },
      captureCancel(){
          this.stepCam = false
          this.step1 = true
      },
      setImage(output) {
        /**/
        this.hasImage = true;
        this.image = output;
        console.log('type', output.type)
        console.log(output)
      },
      getCameras(data){
          this.cameras = data
          console.log(data)
          console.log(this.$refs.webcam.getScreenHeight())
          console.log(this.$refs.webcam.getScreenWidth())
          console.log(this.$refs.webcam)
          this.selCamera = 0
          this.videoMirror = true
          this.loading.close()
      },
      confirm(){
          console.log('confirm')
        this.working = true
        let formData = new FormData();
        formData.append("file", this.image);
        formData.append("name", this.name);
        formData.append("userHeight", this.userHeight);
        formData.append("device", this.device);
        formData.append("user", this.user);
        formData.append("isUser", this.isUser);
        formData.append("maxContentLength", Infinity);
        formData.append("maxBodyLength", Infinity);
        formData.append("selfie", this.capturedImg);
        this.$httpFile.post('/set-relation/' + this.id, formData).then(response => {
            console.log(response);
            if (response.data.error) {
                this.working = false
                this.error = true
                this.avatar = avatarDefault
            } else{
                this.user = response.data.relation.user
                this.avatar = response.data.urls.preview
                this.working = false
                this.done = true
                if (!this.isUser){
                this.confirmAvatar()
                }
            }
        })
      },
      camera(){
        // console.log('tams', this.$vssWidth, this.$vssHeight)
        this.loading = this.$vs.loading()  
        this.stepCam = true
      },
      changeAvatar(){
        this.avatar = avatarDefault
        this.image = null
        this.hasImage = false
        this.step2 = false
        this.step1 = true
      },
      confirmAvatar(){
          const data = {user: this.user, device: this.device}
            this.$http.post('/confirm-avatar/', JSON.stringify(data)).then(resp => {
                if (!resp.data){
                    this.error = true
                    this.avatar = avatarDefault
                }
            })
      }, 
      goBack(){
          if (this.isUser === true){
             this.$router.push('/user');
          } else { 
            this.reset()
            this.step3 = false
            this.step1 = true
          }
        
        this.step3 = false
        this.step1 = true
      },
    },
    watch: {
        $route (to, from){
            if (to !== from) {
                this.getData()
            }
        },
        selCamera() {
            this.camId = this.cameras[this.selCamera].deviceId
        },
        name(){
            if (this.name != "") {
                this.hasName = true
            } else {
                this.hasName = false
            }
        },
        selDevice(){
            if (this.selDevice !== "") {
                this.hasDevice = true
                this.readyDisabled = false
            } else {
                this.hasDevice = false
                this.readyDisabled = true
            }
        },
        step(){
            if (this.step === 3 && this.$route.query.deviceId && this.device !== ''){
                this.confirm()
            }
        },
    }

}
</script>
<style scoped>
body {
    margin: 0px !important;
    padding: 0px !important;
}
</style>
<style>
#fileInput {
  display: none;
}
.colElement{
    width: 80%!important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}
.vs-loading__load__text{
font-family: Poppins,Helvetica,Arial,sans-serif;
font-size: 16px;
}
.link{
 cursor: pointer;
 text-decoration: underline;
}
.link:hover{
 text-decoration: none;
}
a{ 
    color:#055cd5
}

.videoMirror
{
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}

@media (max-width: 600px) {
  .no-offset{
    margin: 0px !important;
  }
  .vertical-align{
    flex-direction: column !important;
  }
  video{
      height: 100%!important;
      margin-top: 50px;
      margin-bottom: 50px;
  }
}
.installationHeader{
    display: block;
    background-color:#000;
    width: 100%;
    height: auto;
    padding: 15px 0 1px 0!important;
}
.installationHeader h1{
    color: #fff;
    font-size: medium;
    margin-top:-15px!important;
}
.step{
    display: flex;
    width: 65px;
    height: 65px;
    background-color: #000;
    color: #fff;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.steps{
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.vs-button.instBtn{
    border-radius: 0px!important;
    color: #000!important;
    font-size: 25px!important;
    font-weight: 900!important;
    background-color: #F42958!important;
    position: absolute!important;
    bottom: 15px!important;
    width: calc(100% - 40px)!important;
    padding:20px!important;
}
.instInput .vs-input{
    border: 2px solid #000!important;
    border-radius: 0px!important;
    background: #fff!important;
    padding: 20px!important;
    font-size: 25px!important;
}
.instInput {
    margin-bottom:15px;
    width:100%
}
.back{
    position: absolute;
    bottom: 120px;
    left: 20px;
    font-size: 20px;
    cursor: pointer !important;
}
.devices{    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}
.device{
    min-width: 26px!important;
    height: 26px!important;
    margin: 10px;
    border: solid 2px #000;
    padding: 5px;
    cursor: pointer;
}
.selected{
    background-color: #F42958!important;
}
.ps__rail-y, .ps-container > .ps-scrollbar-y-rail {   
       opacity: 1 !important
       }

@media only screen and (max-width: 600px) {
  video {
    height: 100%!important;
    margin-top: 0px;
    margin-bottom: 0px;
}
}
</style>
