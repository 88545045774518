<template>
<div v-if="loaded">
<installation-form :userdata="userdata" :instId="instId"/>
</div>
</template>
 <script>
    import installationForm from './Installation.vue';
    export default {
         data:() => ({
        instId: null,
        loaded: false,
      }),
    components: {
    installationForm
    },
     props: ['userdata'],
     mounted(){
        this. getInstallation()
     },
     methods: {
        getInstallation(){
          this.$http.get('/installation-user/' + this.userdata.userId).then(response => {
            const data = response.data.response
            this.instId = data
            this.loaded = true
          })
        .catch(() => {
          this.error = true;
        });
        },
     },
    }
 </script>