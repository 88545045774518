<template>
    <div class="center mb-80">
      <vs-navbar target-scroll="#hide-scroll-content" center-collapsed v-model="active">
        <template #left>
          <router-link  to="/" ><img style="padding: 7px 5px 3px; max-height:30px" src="@/assets/h-logo.png" alt=""></router-link>
        </template>
        <vs-navbar-item v-if="userType === 9"  :active="active === 'users'" id="users" @click="goto('Users')">
          Users
        </vs-navbar-item>
        <vs-navbar-item v-if="userType === 9" :active="active === 'installations'" id="installations" @click="goto('Installations')">
          Installations
        </vs-navbar-item>
        <vs-navbar-item v-if="userType !== 9"  :active="false" id="nullbar" />
        <template #right>
           <vs-avatar>
              <img src="/avatars/avatar-5.png" alt="">
            </vs-avatar>
          <vs-button v-if="logoutButton" @click="logout()">Logout</vs-button>
        </template>
      </vs-navbar>
      <div v-if="userType === 9" class="mobile-nav">
        <button class="vs-navbar__item" :class="(active === 'users')? 'active': ''" @click="goto('Users')"> Users </button>
        <button class="vs-navbar__item" :class="(active === 'installations')? 'active': ''" @click="goto('Installations')"> Installations </button>
      </div>
    </div>
  </template>
  <script>
  export default {
    data:() => ({
      active: 'users',
      logoutButton: false,
    }),
    props:['userType', 'userdata'],
    mounted(){
      console.log('TRACE:' + this.$route.meta.public )
      this.active = this.$route.meta.section
      console.log(this.userdata)
      if (this.userdata.userId !== undefined ){
          this.logoutButton = true
        }
      },
    methods: {
      goto(page){
        if ( this.$route.name !== page){
         this.$router.push({ name : page});
        } 
      },
      logout(){
        this.$cookies.remove('loginUnivrse')
        this.$router.push({name: 'login'});
      }
    },
    watch: {
        $route (){
        this.active = this.$route.meta.section
        if (this.userdata.userId !== undefined ){
          this.logoutButton = true
        }
    },
      userdata(){
        if (this.userdata.userId !== undefined ){
          this.logoutButton = true
        }
      }
    }
  }
  </script>
  <style>
      .vs-navbar-content{
        background: whitesmoke!important;
      }
.mb-80{
    margin-bottom: 80px;
}
.mobile-nav{
 display: none; 
 justify-content: center; 
 margin:50px
}
@media (max-width: 600px) {
  .mobile-nav{
 display: flex; 
 }
}
  </style>