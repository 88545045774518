import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'boxicons/css/boxicons.min.css'
import VueCookies from 'vue-cookies'
import http from './plugins/http.js'
import Swal from 'vue-sweetalert2';
import DisableAutocomplete from 'vue-disable-autocomplete';
import VueScreenSize from 'vue-screen-size'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)
Vue.use(VueScreenSize)
Vue.use(DisableAutocomplete);

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(Swal);

Vue.use(http)

Vue.use(VueCookies)

Vue.use(Vuex)
Vue.use(Vuesax, {
    colors: {
        primary: '#055CD5',
        secondary: '#F42A58',
        success: 'rgb(23, 201, 100)',
        danger: 'rgb(242, 19, 93)',
        warning: 'rgb(255, 130, 0)',
        dark: 'rgb(36, 33, 69)'
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')