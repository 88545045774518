<template>
<devices-form :userdata="userdata" :instId="124"/>
</template>
 <script>
    import devicesForm from './devices.vue';
    export default {
    components: {
    devicesForm
    },
     props: ['userdata'],
    }
 </script>