import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import User from '../views/User.vue'
import userView from '../views/UserView.vue'
import deviceView from '../views/deviceView.vue'
import Installations from '../views/Installations.vue'
import Installation from '../views/Installation.vue'
import PublicInstallation from '../views/PublicInstallation.vue'
import Devices from '../views/devices.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { public: true }
    }, {
        path: '/registration',
        name: 'Register',
        component: Register,
        meta: { public: true }
    },
    {
        path: '/',
        redirect: 'login',
        meta: { public: true }
    },
    {
        path: '/user',
        name: 'User',
        component: userView,
        meta: { section: 'user', public: false }
    },
    {
        path: '/installation',
        name: 'uInstallation',
        component: userView,
        meta: { section: 'installations', public: false }
    },
    {
        path: '/users',
        name: 'Users',
        component: Home,
        meta: { section: 'users', public: false }
    },
    {
        path: '/users/:id',
        name: 'Userview',
        component: User,
        meta: { section: 'users', public: false }
    },
    {
        path: '/installations',
        name: 'Installations',
        component: Installations,
        meta: { section: 'installations', public: false }
    },
    {
        path: '/installations/:id',
        name: 'Installation',
        component: Installation,
        meta: { section: 'installations', public: false }
    },
    {
        path: '/installation/:id',
        name: 'PublicInstallation',
        component: PublicInstallation,
        meta: { section: 'installations', public: true }
    },
    {
        path: '/installations/:id/devices',
        name: 'Devices',
        component: Devices,
        meta: { section: 'installations', public: false }
    },
    {
        path: '/devices',
        name: 'uDevices',
        component: deviceView,
        meta: { section: 'installations', public: false }
    }
]

const router = new VueRouter({
    routes
})

export default router