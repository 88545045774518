<template>
  <div v-if="check">
    <div id="app" v-if="check && ($route.name !== 'PublicInstallation')" >
      <nav-sidebar v-if="$route.name !== 'Login' && $route.name !== 'Register'" :userType = "userType" :userdata="userdata"/>
      <vs-row>
          <vs-col offset="2" lg="8" sm="12">
          <router-view :userdata="userdata"/>
          </vs-col>
        </vs-row>
    </div>
    <div id="app" v-if="($route.name === 'PublicInstallation')">
      <router-view :userdata="userdata"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import NavSidebar from '@/components/NavSidebar.vue'

export default {
  components: {
    NavSidebar
  },
  data:() => ({
    check: false,
    loading: '',
    userType: 0,
    userdata: {user: ''},
    refreshCtrl: 0,
  }),
  mounted(){
    this.checkUser()
  },
  methods: {
    checkUser(){
      this.refreshCtrl += 1
      this.userdata = {}
      this.loading = this.$vs.loading()
      this.check = false
      if (this.$cookies.isKey('loginUnivrse')) {
            const token = this.$cookies.get('loginUnivrse')
            this.$http.get('/get-user/' + token).then(resp => {
              this.userdata = resp.data.response.user;
              this.userType = this.userdata.userType
              this.check = true
              this.loading.close()
              if (this.$route.name === "Login" || this.$route.name === "Register"){
                if (this.userType === 9){
                this.$router.push('/users');
                } else {
                this.$router.push('/user');
              }
              }
              
              })
            .catch(() => {
                  this.$cookies.remove('loginUnivrse');
                  if (this.$route.meta.public !== true){
                  this.$router.push({ name: 'Login' })
                  this.check = true;
                  this.loading.close()
              }
            });
      } else {
        if (this.$route.meta.public !== true){
            this.$router.push({ name: 'Login' })
        }
            this.check = true;
          this.loading.close()
      }
    }
  },
  watch: {
    $route(){
      this.checkUser()
    },
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a{
  color: #2c3e5048;
  text-decoration: none;
}
a:hover{
  color: #2c3e50;
}
@media (max-width: 600px) {
  .vs-col--offset-2 {
    margin: 0px!Important;
    padding: 20px!Important;
  }
  #app .mb-80{
    margin-bottom: -60px!important;
  }
  .mobile-blank-space{
    display: block;
    height: 20px!important;
  }
}
</style>
