<template>
     <div v-if="loaded">
      <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="6" sm="12">
       <h2 v-if="userdata.userType === 9"  style="text-align:left"><a href="/#/installations/">INSTALLATIONS</a> <span style="color:#2c3e50481!important;font-weight: 100 ">/</span>  <a :href="'/#/installations/' + InstallationId ">{{ ID }}</a> <span style="font-weight: 400">/ Devices</span></h2>
      <h2 v-if="userdata.userType === 2"  style="text-align:left"><a :href="'/#/installation'">{{ ID }}</a> <span style="font-weight: 400">/ Devices</span></h2>
      </vs-col>
      <vs-col class="float-right" vs-type="flex" vs-justify="right" vs-align="right" style="text-align: right"  w="6" sm="12">
        <vs-button
        flat
        :active="true"
        @click="openForm"
      >
        Add Device
      </vs-button>
      </vs-col>
    </vs-row>
    <div class="mobile-blank-space"></div>
     <vs-table
        v-model="selected"
        >
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th sort @click="devices = $vs.sortData($event ,devices, 'deviceName')">
              Device Name
            </vs-th>
            <vs-th sort @click="devices = $vs.sortData($event ,devices, 'serialNamber')">
              Device Serial Number
            </vs-th>
            <vs-th>
     
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(devices, search), page, max)"
            :data="tr"
            :is-selected="!!selected.includes(tr)"
            not-click-selected

          >
            <vs-td>
            {{ tr.deviceName }}
            </vs-td>
            <vs-td>
              {{ tr.serialNumber }}
            </vs-td>
            <vs-td style="text-align: center!important;">
            <vs-button
        flat
        danger
        :active="true"
        @click="deleteDevice(tr.deviceId)"
      >
       Delete
      </vs-button>
            </vs-td>
          </vs-tr>
        </template>
          <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(devices, search), max)" />
        </template>
      </vs-table>
      </div>
</template>
 <script>
import Vue from 'vue'
import NewDevice from './AddDevice.vue'
    export default {
      data:() => ({
        ID: '',
        InstallationId: '',
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: 0,
        selected: [],
        devices: [],
        loaded: false,
      }),
      props: ['userdata', 'instId'],
      mounted(){
        if (this.userdata.userType === 9){
        this.ID = this.$route.params.id
        this.InstallationId = this.ID
        this.getInstallations()
        } else if (this.userdata.userType === 2){
        this.ID = this.instId
        this.InstallationId = this.instId
        this.getInstallations()
        } else {
          this.$router.push('/');
        }
      },
      methods: {
        getInstallations(){
          this.$http.get('/get-installation/' + this.InstallationId).then(response => {
            console.log(response)
            const data = response.data.response
            this.InstallationId =  parseInt(data.id)
            this.InstallationName = data.name
            this.devices = data.devices
            this.ID = data.name
            this.loaded = true
          })
        .catch(() => {
          this.$router.push('/installations');
        });
      },
    openForm() {
      const deviceForm = new Vue({
        ...NewDevice,
        parent: this,
        propsData: {addDevice: this.addDevice},
      }).$mount()
      this.$swal({
        title: '<span class="font-weight-bolder">New Device</span>',
        html: deviceForm.$el,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        focusCancel: false,
      })
    },
        addDevice(data){
           this.$http.post('/device/' +   this.InstallationId, JSON.stringify(data)).then(() => {
              this.getInstallations()
              this.$swal.close()
          })
        .catch(() => {
          this.error = true;
        });
        },
        deleteDevice(id) {
          this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.delete('/device/' + this.InstallationId + '/' + id).then(() => {
              this.$swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.getInstallations()
           })
          }
        })
        },

      }
    }
    </script>
    <style type="text/css">
    .vs-table__td{
      text-align:left!important
    }
      .th-center div{
      text-align:center!important
    }
    </style>
