 <template>
    <div v-if="loaded">
      <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
       <h2 style="text-align:left"><a href="/#/users/">USERS</a> <span style="font-weight: 400">/ {{ ID }}</span></h2>
      </vs-col>
      <vs-col class="float-right" vs-type="flex" vs-justify="right" vs-align="right" style="text-align: right"  w="2" sm="12">
        <vs-button
        flat
        :active="true"
        @click="update"
      >
        Update
      </vs-button>
      <vs-button
        v-if="userId !== myUserId"
        flat
        danger
        @click="deleteUser"
      >
        Delete
      </vs-button>
      </vs-col>
    </vs-row>

     <vs-row style="margin-top: 30px">
        <vs-col class="no-offset" offset="2" w="8" sm="12">
           <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="3">
         User ID:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="9">
            {{ userId }}
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         Name:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
              <vs-input v-model="UserName" placeholder="Name" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         E-mail:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
              <vs-input v-model="UserEmail" placeholder="E-mail" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         Password:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
             <vs-input type="password" icon-after v-model="UserPass"
        :visiblePassword="true" @click-icon="genPassword">>
        <template #icon>
          <i class='bx bx-reset'></i>
        </template>
      </vs-input>
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
        AWS Bucket ID:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
              <vs-input v-model="AWSBucket" placeholder="Name" />
        </vs-col>
      </vs-row>

      <div class="limits">
          Usage Limits
      </div>
    <vs-row class="inline" style="margin-top: 40px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="4">
        <vs-input
        label="Workspace"
        v-model="Workspace"
        type="number"
        placeholder="1"
      />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="4">
                    <vs-input
        label="Storage (mb)"
        v-model="Storage"
        type="number"
        placeholder="100"
      />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="4">
                   <vs-input
        label="CC Participants"
        v-model="Participants"
        type="number"
        placeholder="Participants"
      />
        </vs-col>
      </vs-row>
        </vs-col>
      </vs-row>


    </div>
  </template>
  <script>
    var generator = require('generate-password');
    import 'sweetalert2/dist/sweetalert2.min.css';

    export default {
      data:() => ({
        ID: '',
        userId: '',
        UserName: '',
        UserEmail: '',
        UserPass: '',
        AWSBucket: '',
        Workspace: '',
        Storage: '',
        hasVisiblePassword: false,
        Participants: '10',
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: 0,
        myUserId: 0,
        loaded: false,
      }),
      props: ['userdata'],
      mounted(){ 
        if (this.userdata.userType === 9){
        this.myUserId = parseInt(this.userdata.userId)
        this.ID = this.$route.params.id
        this.getUser()
        } else {
          this.$router.push('/');
        }
      },
      methods: {
        getUser(){
           const data = {userId: this.$route.params.id}
           this.$http.post('/get-user', JSON.stringify(data)).then(response => {
            const userdata = response.data.response.user
            this.userId =  parseInt(userdata.userId)
            this.UserName = userdata.userName
            this.UserEmail = userdata.email
            this.AWSBucket = userdata.AWSBucketId
            this.Workspace = userdata.limits.workspace
            this.Storage = userdata.limits.storage
            this.Participants = userdata.limits.user
            this.ID = this.UserName
            this.loaded = true
          })
        .catch(() => {
          this.error = true;
        });
        },
        update() {
          if (this.UserPass !== ''){
          this.$swal.fire({
          title:  'Are you sure?',
          text:  'Password will be changed',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, update it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateData(() =>{
              this.$swal.fire({
                title:  'Updated!',
                text:  'User data has been updated.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
          }
        })
          } else{
            this.updateData(() =>{
              this.$swal.fire({
                title:  'Updated!',
                text:  'User data has been updated.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
          }
        },
        updateData(callback){
          const data ={ userName: this.UserName, email:this.UserEmail, pass: this.UserPass, AWSBucketId: this.AWSBucket, limit: { workspace: this.Workspace, storage: this.Storage, user: this.Participants }}
           this.$http.put('/user/' + this.userId, JSON.stringify(data)).then(() => {
            callback(true)
           })
        },
        deleteUser() {
          this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.delete('/user/' + this.userId).then(() => {
              this.$swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.$router.push('/');
           })
          }
        })
        },
        genPassword(){
          var password = generator.generate({
              length: 10,
              numbers: true
            });
            this.UserPass = password;
        }
      }
    }
    </script>
    <style type="text/css">
    .vs-input__icon{
      cursor: pointer!important;
    }
    .swal2-container{
      font-family: Poppins, Helvetica, Arial, sans-serif;
    }
    .float-right button{
        float:right;
    }
      .th-center div{
      text-align:center!important
    }
    .inline input{
        margin-right:20px !important;
    }
    input{
        width: 100%!important;
    }
    .limits{
     margin-top: 30px;
    background-color: whitesmoke;
    padding: 20px;
    font-weight: 700;
    font-size: larger;
    }
    
@media (max-width: 600px) {
     .float-right button{
        float:left !important;
    }
  .no-offset{
    margin: 0px !important;
  }
  .vertical-align{
    flex-direction: column;
  }
}
    </style>
